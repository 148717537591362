.wallet-wrapper {
  width: 96%;
  margin: 5px auto;
  border-radius: 10px;

  margin-top: 75px;
  padding-left: 8px;
  padding-right: 8px;
}
.header-wallet {
  display: flex;
  padding: 8px 16px;
  background-color: #579b52;
  align-items: center;
  margin: 0 auto;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.header-wallet img {
  height: 32px;
  width: 38px;
}
.header-wallet .header-text {
  font-size: 20px;
  color: #fff;
  font-weight: 500;
  padding-left: 8px;
}
.img-container {
  margin: 0 auto;
}
.img-container img {
  width: 100%;
  margin: 0 auto;
}

.item-image {
  width: 343px;
  height: 192px;
}

.wrappper {
  background-color: #579b52;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.wallet-content {
  padding: 20px 10px 65px;
}
.content-heading {
  font-size: 12px;
  color: #f7e6c0;
}
.content-sub-heading {
  font-size: 12px;
  color: #fff;
  padding-top: 4px;
  padding-bottom: 10px;
  line-height: 18px;
}
.order-details {
  display: flex;
  justify-content: space-between;
}
.wallet-footer {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px;
  align-items: center;
}
.wallet-footer img:first-child {
  width: 24px;
}
.wallet-footer img:last-child {
  width: 26px;
  height: 30px;
}

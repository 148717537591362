.header {
  display: flex;
  justify-content: center;
  width: 96%;
  align-items: center;
  margin: 0 auto;
}
.page-content {
  display: unset;
  top: 2%;
}
.page-content-title {
  font-size: 48px;
  line-height: 120px;
  font-weight: 400;
  margin-bottom: 15px;
}
.content-wrapper {
  text-align: center;
  width: 80%;
  margin: 30px auto;
  max-width: 1280px;
}
.content-wrapper-innr {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .becknIcon > img {
    margin: 0 auto;
    display: flex;
  }
  .header a {
    right: unset !important;
  }
  .page-content-title {
    font-size: 30px;
    line-height: unset;
  }
  .content-wrapper {
    width: 90%;
    height: unset;
  }
  .content-wrapper-innr {
    margin-top: unset;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .header a {
    right: unset !important;
  }
  .page-content-title {
    font-size: 50px;
    line-height: unset;
  }
  .content-wrapper {
    width: 90%;
    height: unset;
  }
  .content-wrapper-innr {
    margin-top: unset;
    margin-bottom: 54px;
  }
  .iframeVideoBeck {
    height: 40%;
    width: 92%;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1400px) {
  .page-content-title {
    font-size: 50px;
    line-height: unset;
  }
}

.ant-progress-text {
  display: none !important;
}
.ant-progress-outer {
  /* display: none; */
  /*  */
}
.ant-progress-inner {
  background: #523f62 !important;
}
.ant-progress-bg {
  background: linear-gradient(269.49deg, #4383BF 0%, #CFE4F5 100%) !important;
}
.beckn-image {
  display: flex;
  margin-top: 90px;
  margin-left: 100px;
}
.main-containers {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70%;
}
.headingOfProgress {
  font-weight: 400;
  font-size: 36px;
  color: #4e4646;
}
.headingOfHeader {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}
.progress-bar {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}
.progress {
  width: 75%;
  position: relative;
  left: 15px;
}
.data-set-container{
    display: flex;
  justify-content: center; 
}
.data-set{
font-weight: 300;
font-size: 20px;
line-height: 30px;
text-align: center;
color: #000000;
}

.modal_inner_wrapper {
  display: flex;
  justify-content: center;
}
.PCM_modal .ant-modal-content .ant-modal-header .ant-modal-title {
  text-align: center;
  padding-bottom: 30px;
  font-size: 17px !important;
  font-weight: 700 !important;
  color: #4e4646 !important;
}
.PCM_modal.ant-modal {
  width: 1150px !important;
  height: 660px !important;
}
.PCM_modal.ant-modal .ant-modal-footer > button {
  display: none;
}
.PCM_modal.ant-modal .ant-modal-header {
  background-color: unset;
}
.PCM_modal.ant-modal .ant-modal-content {
  padding: 25px 20px;
  background-color: #f7f7f7;
}
.steps {
  height: 188px;
  width: 188px;
}
.steps_container > div:first-child {
  color: #4e4646 !important;
}
.steps_container {
  display: flex;
  flex-direction: column;
  width: 198px;
  margin: 10px;
}
.PCM_modal p {
  text-align: left !important;
  font-size: 11px;
  font-weight: 400;
  text-transform: lowercase;
  color: #4e4646;
}
.PCM_modal ul li {
  text-align: left !important;
  font-size: 11px;
  font-weight: 400;
  margin-left: 10px;
  padding-bottom: 10px;
  text-transform: lowercase;
  color: #4e4646;
}
.PCM_modal ul li:last-child {
  padding-bottom: unset !important;
}
.none {
  display: none !important;
}

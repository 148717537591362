.ondc_dashboard {
  width: 640px;
  height: 551px;
  border: 1px solid #3d4c68;
  border-radius: 16px;
  background: #3d4c68;
  color: #fff;
  display: grid;
  align-content: center;
  text-align: center;
}
.ondc_demo_btn {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 30px 0;
  width: 180px;
  height: 40px;
  background-color: #fff;
  border-radius: 26px;
  cursor: pointer;
  font-size: 18px;
}
.ondc_video_modal .ant-modal-footer {
  display: none;
}
.ondc_video_modal button {
  display: none;
}
.ondc_video_modal .ant-modal-content {
  padding: unset;
}
.ondc_video_modal .ant-modal-body {
  margin: unset;
}
.uei_exitBtn {
  position: absolute;
  bottom: 102px;
  width: 1080px;
  cursor: pointer;
}
.uei_exitBtn img {
  position: absolute;
  right: -210px;
}
@media screen and (min-width: 768px) and (max-width: 1366px) {
  .regenUEI_tab_wrappe {
    margin-left: 80px;
  }
}

.welcome-Screen-container {
  display: flex;
  justify-content: center;
  /* height: 100vh; */
  overflow: hidden;
  max-width: 1060px;
  margin: 0 auto;
}

/*=== Trigger  ===*/
.animate {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.five {
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
/*=== FADE IN  ===*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  margin-top: 94px;
}
.child-container {
  text-align: center;
  /* display: grid; */
}
.Beckn-logo {
  height: 50px;
  width: 161px;
  cursor: pointer;
  position: relative;
}
.welcomeQuote {
  font-weight: 400;
  font-size: 64px;
  line-height: 96px;
  color: #4e4646;
}
.headLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}
button {
  font-size: 28px;
  font-family: "Space Grotesk";
  font-weight: 300;
  letter-spacing: 0.03em;
  line-height: 41px;
  color: #0c0c0c;
  width: 228px;
  height: 55px;
  background: #77aede;
  border: none;
  cursor: pointer;
}
.welcom-begin-button {
  border-radius: 5px;
  font-size: 28px;
  font-weight: 400;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .welcome-Screen-container {
    height: 100vh !important;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .welcome-Screen-container {
    height: unset !important;
  }
}

@media only screen and (min-device-width: 820px) and (max-device-width: 1180px) and (orientation: landscape) {
  .welcome-Screen-container {
    height: unset !important;
  }
}
@media only screen and (min-device-width: 820px) and (max-device-width: 1180px) and (orientation: portrait) {
  .welcome-Screen-container {
    height: 80vh !important;
  }
  .fadeinout {
    align-items: unset !important;
  }
}
